import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import ReactMarkdown from "react-markdown";

export default function MarkdownViewer(props) {
    const {url} = props;

    const [text, setText] = useState('');

    useEffect(() => {
        open(url);
    }, [url]);

    function open(url) {
        const rawFile = new XMLHttpRequest()
        rawFile.open('GET', url, false)
        rawFile.onreadystatechange = () => {
            if (rawFile.readyState === 4) {
                if (rawFile.status === 200 || rawFile.status === 0) {
                    setText(rawFile.responseText);
                    return
                }
            }
            setText('-')
        }
        rawFile.send()
    }

    return (
        <pre style={{fontFamily: 'inherit', margin: '16px 0px 0px 0px', whiteSpace: 'pre-wrap', lineHeight: 1.1}}>
            <ReactMarkdown children={text}/>
        </pre>
    );
}

MarkdownViewer.propTypes = {
    url: PropTypes.string.isRequired
};

import ChangeLanguage from './ChangeLanguage';
import React from 'react';
import PropTypes from 'prop-types';
import authService from '../services/authService';
import { Menu, Icon, Badge, Popover, Row, Avatar, Empty } from 'antd';
import { HashLink as Link } from 'react-router-hash-link';
import {connect} from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { toggleModal } from '../actions/modal';
import {ajax, getDocuments} from "../helper";

class MenuMarkup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deputy: undefined
        }
    }

    getDeputy() {
        if (!localStorage.getItem('JWT')) { return; }
        return ajax()
            .get('/deputy')
            .then((response) => {
                this.setState({deputy: response.data.deputyEmail})
            })
            .catch((e) => {
                console.error(e);
            });
    }

    componentDidMount() {
        this.getDeputy();
    }

    deputyNoty = () => {
        const { translate } = this.props;
        const { deputy } = this.state;

        if (!deputy) {
            return <Empty description={<span>{translate('noNotification')}</span>} />;
        } else {
            return (
                <React.Fragment>
                    {deputy && (
                        <Row
                            type="flex"
                            style={{ marginTop: 12 }}>
                            <Avatar
                                size={50}
                                shape="square"
                                icon="usergroup-add"
                                style={{ marginRight: 10 }}
                            />
                            <div style={{ marginBottom: 5 }}>
                                <p style={{ marginBottom: 5 }}>
                                    {translate('notificationDeputyText', {
                                        email: deputy
                                    })}
                                </p>
                                <p>
                                    <Link to="/settings#deputy">{translate('notificationDeputyModifyLink')}</Link>
                                </p>
                            </div>
                        </Row>
                    )}
                </React.Fragment>
            );
        }
    };

    showNoProfile() {
        let user = localStorage.getItem('user');
        return (
            user !== null &&
            user !== '' &&
            localStorage.getItem('profileCode') === 'registered' &&
            authService.getIsMicrosecOIDEnabled()
        );
    }

    render() {
        const { translate, activeLinkKey, lightStyle, mobileVersion } = this.props;
        const { deputy } = this.state;
        return (
            <Menu
                theme={lightStyle || mobileVersion ? 'light' : 'dark'}
                mode={mobileVersion ? 'vertical' : 'horizontal'}
                selectedKeys={[`${activeLinkKey}`]}
                style={{ border: 0 }}>
                {this.showNoProfile() && (
                    <Menu.Item key="profileName">
                        {' '}
                        <a
                            href={getDocuments('order', localStorage.getItem('language'))}
                            target="_blank"
                            rel="noopener noreferrer">
                            {translate('noProfileName')}
                        </a>
                    </Menu.Item>
                )}
                {authService.isRegistered() && (
                    <Menu.SubMenu
                        title={
                            <span>
                                <Icon
                                    type="user"
                                    style={{ fontSize: 20 }}
                                />
                                {authService.getUserEmail()}
                                <Icon
                                    type="down"
                                    theme="outlined"
                                    style={{ fontSize: 10, marginLeft: 8 }}
                                />
                            </span>
                        }>
                        {(!authService.isAdmin()) && (
                            <Menu.Item key="settings">
                                <Link to="/settings">
                                    <Icon type="setting"/>
                                    {translate('header.links.settings.text')}
                                </Link>
                            </Menu.Item>
                        )}
                        {(!authService.isAdmin()) && (
                            <Menu.Item key="bulkSigning">
                                <Link to="/bulkSigning">
                                    <Icon type="bars"/>
                                    {translate('header.links.tools.massSign')}
                                </Link>
                            </Menu.Item>
                        )}
                        {(!authService.isAdmin()) && (
                            <Menu.Item key="waitingToSignByOthers">
                                <Link to="/waitingToSignByOthers">
                                    <Icon type="reconciliation"/>
                                    {translate('header.links.waitingtosignbyothers')}
                                </Link>
                            </Menu.Item>
                        )}
                        <Menu.Item key="logout">
                            <Link to="/logout">
                                <Icon type="logout" />
                                {translate('header.links.logout.text')}
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                )}
                { (!authService.isAdmin()) && authService.isRegistered() && window.config.REACT_APP_DEPUTY_FUNCTION_AVAILABLE && (
                    <Menu.Item
                        key="notification"
                        style={{ border: 'none' }}>
                        <Popover
                            placement="bottomRight"
                            title={translate('notificationTitle')}
                            content={this.deputyNoty()}
                            trigger="hover">
                            <Badge dot={deputy !== undefined}>
                                <Icon
                                    type="message"
                                    style={{ fontSize: 20, margin: '0 10px 0 0' }}
                                />
                                {translate('notificationTitle')}
                                <Icon
                                    type="down"
                                    theme="outlined"
                                    style={{ fontSize: 10, marginLeft: 8 }}
                                />
                            </Badge>
                        </Popover>
                    </Menu.Item>
                )}
                <Menu.Item
                    key="lang"
                    style={{ border: 'none' }}>
                    <ChangeLanguage
                        isBgLight={lightStyle || mobileVersion}
                        block={mobileVersion}
                    />
                </Menu.Item>
            </Menu>
        );
    }
}

MenuMarkup.propTypes = {
    translate: PropTypes.func.isRequired,
    activeLinkKey: PropTypes.string.isRequired,
    lightStyle: PropTypes.bool.isRequired,
    mobileVersion: PropTypes.bool,
    onSubMenuItemClick: PropTypes.func,
    showMassSignModal: PropTypes.func.isRequired,
    deputy: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        deputy: state.deputy,
        translate: getTranslate(state.locale)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showMassSignModal: () => {
            dispatch(toggleModal('MASS_SIGN'));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuMarkup);

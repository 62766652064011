import 'babel-polyfill'; // IE 11 specific dependency do not try to remove it!
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './css/style.css';
import AdminLogin from './components/AdminLogin';
import AdminPage from './components/AdminPage';
import BulkSigning from './components/BulkSigning';
import Changelog from './components/Changelog';
import ChangePassword from './components/pages/ChangePassword';
import Confirm from './components/Confirm';
import DownloadFile from './components/DownloadFile';
import EditUser from './components/EditUser';
import FrequentlyUsedEmailAddresses from './components/FrequentlyUsedEmailAddresses';
import Login from './components/Login';
import LoginAD from './components/LoginAD';
import Logout from './components/Logout';
import HomePage from './components/pages/HomePage';
import ModalContainer from './components/modals/ModalContainer';
import NewUserEmailRegister from './components/NewUserEmailRegister';
import PrivateRoute from './components/PrivateRoute';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import Registered from './components/Registered';
import RegisteredAD from './components/RegisteredAD';
import ResetSecondFactor from './components/ResetSecondFactor';
import Saml from './components/Saml';
import Settings from './components/Settings';
import InfoPage from './components/InfoPage';
import WorkflowPage from './components/workflow/WorkflowPage';
import axios from 'axios/index';
import configureStore from './store/configureStore';
import withTracker from './components/withTracker';
import { Alert, Spin } from 'antd';
import {Provider} from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import {addTranslationForLanguage, initialize} from 'react-localize-redux';
import { getHistory } from './helper';
import { unregister } from './registerServiceWorker';

let packageJson = require('../package.json')
const ajax = axios.create();

const store = configureStore();
const languages = ['hu', 'en', 'pl'];

/**
 * The first in the languages array will be the default.
 * Pass the defaultLanguage to change that behavior.
 * store.dispatch(initialize(languages, { defaultLanguage: '...' }));
 */
const defaultLanguage = localStorage.getItem('language') ? localStorage.getItem('language') : 'hu';
store.dispatch(initialize(languages, { defaultLanguage: defaultLanguage, showMissingTranslationMsg: false}));

function loadLocale(locale, onFinished) {
    ajax.get('locales/' + locale + '.json?' + packageJson.version).then((response) => {
        store.dispatch(addTranslationForLanguage(response.data, locale));
        onFinished();
    });
}

function loadLocales(onFinished) {
    loadLocale(languages[0], function () {
        loadLocale(languages[1], function () {
            loadLocale(languages[2], function () {
                onFinished();
            });
        });
    });
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clientConfigGot: false,
            error: null,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getClientConfig();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getClientConfig() {
        let configUrl;
        if (process.env.NODE_ENV === 'development') {
            configUrl = `${process.env.REACT_APP_API_URL}api/clientConfig`;
        } else {
            configUrl = 'api/clientConfig';
        }
        ajax.get(configUrl)
            .then((response) => {
                const { data } = response;

                window.initializeESignState(`https://${data.esignProxyHost}/esign`);

                ReactGA.initialize(data.googleTagManagerId);

                const maxExtensionLength = ".asice".length;

                window.config = {
                    REACT_ONLY_ADMIN: data.onlyAdmin,
                    REACT_ADMIN_ENABLE: data.adminEnable,
                    REACT_APP_FACEBOOK_APP_ID: data.facebookAppId,
                    REACT_APP_GOOGLE_CLIENT_ID: data.googleClientId,
                    REACT_APP_RECAPTCHA_SITE_KEY: data.googleReCaptchaSiteKey ? data.googleReCaptchaSiteKey : undefined,
                    REACT_APP_BASE_URL: data.baseUrl,
                    REACT_APP_SERVICE_BASE_URL: data.apiBaseUrl,
                    REACT_APP_USER_SERVICE_LEVEL_MANAGER_AVAILABLE: data.userServiceLevelManagerAvailable,
                    REACT_APP_TSA_URL: data.tsaUrl,
                    REACT_APP_ACTIVE_DIRECTORY_LOGIN_AVAILABLE: data.activeDirectoryLoginAvailable,
                    REACT_APP_DEPUTY_FUNCTION_AVAILABLE: data.deputy,
                    REACT_APP_SAML_URL: data.samlURL,
                    REACT_APP_RELEASE_DATE: data.releaseDate,
                    REACT_APP_REGEX_NUMBER: data.passwordRegexRules.numbersCount,
                    REACT_APP_REGEX_SPECIAL: data.passwordRegexRules.specialCharCount,
                    REACT_APP_REGEX_MINLENGTH: data.passwordRegexRules.minLength,
                    REACT_APP_REGEX_UPPERCASE: data.passwordRegexRules.upperCaseCount,
                    REACT_APP_BANNER_DAYS: data.bannerDays,
                    REACT_APP_MICROSIGNER_WINDOWS: data.microSignerDownloadURLWin,
                    REACT_APP_MICROSIGNER_MACOS: data.microSignerDownloadURLIOS,
                    REACT_APP_MICROSIGNER_LINUX: data.microSignerDownloadURLLinux,
                    REACT_APP_MICROSEC_PASSWORD_CHANGE_URL: data.microsecPasswordChangeUrl,
                    REACT_APP_MICROSEC_PASSWORD_CHANGE_URL_HU: data.microsecPasswordChangeUrlHu,
                    REACT_APP_MICROSEC_PASSWORD_CHANGE_URL_EN: data.microsecPasswordChangeUrlEn,
                    REACT_APP_WEBSZIGNO_TERMS_AND_CONDITIONS_URLS: data.webszignoTermsAndConditionsURLs,
                    REACT_APP_WEBSZIGNO_PRIVACY_POLICY_URLS: data.webszignoPrivacyPolicyURLs,
                    REACT_APP_WEBSZIGNO_PRICE_LIST_URLS: data.webszignoPriceListURLs,
                    REACT_APP_WEBSZIGNO_ORDER_URLS: data.webszignoOrderURLs,
                    REACT_APP_WEBSZIGNO_MANUAL_URLS: data.webszignoManualURLs,
                    REACT_APP_WEBSZIGNO_PROMO_FOR_MICROSIGNER_URLS: data.webszignoPromoForMicroSignerURLs,
                    REACT_APP_MAX_FILE_UPLOAD_SIZE: data.uploadMaxFileSize,
                    REACT_APP_ADMIN_FILE_DELETE_FUNCTION_AVAILABLE: data.adminFileDelete,
                    REACT_APP_ADMIN_FILE_DOWNLOAD_FUNCTION_AVAILABLE: data.adminFileDownload,
                    REACT_APP_PDF_FORM_START_SIGN_AUTO: data.pdfFormSignStartAuto,
                    REACT_APP_MOBILE_AUTHENTICATION_ENABLED: data.mobileAuthenticationEnabled,
                    REACT_APP_TIMESTAMP_LOGIN_MODE: data.timestampLoginMode,
                    REACT_APP_WORKFLOW_ROLES_ENABLED: data.workflowRolesEnabled,
                    REACT_APP_LOCK_TIME_IN_SEC: data.lockTimeInSec,
                    REACT_APP_PUSH_NOTIFICATIONS_ENABLED: data.pushNotificationsEnabled,
                    REACT_APP_SECOND_FACTOR_MODE: data.secondFactorMode,
                    REACT_APP_ATTRIBUTE_CERT_USAGE: data.attributeCertUsage,
                    REACT_APP_META_DATA_ENABLED: data.metaDataEnabled,
                    REACT_APP_SIGNING_WORKFLOW_FILE_ATTACHMENT_ENABLED: data.signingWorkflowAttachmentEnabled,
                    REACT_APP_SIGNING_WORKFLOW_FILE_ATTACHMENT_LIMIT: data.signingWorkflowAttachmentLimit,
                    REACT_APP_SECOND_FACTOR_TYPE: data.secondFactorType,
                    REACT_APP_CUSTOM_FOOTER_ENABLED: data.customFooterHTMLEnabled,
                    REACT_APP_CUSTOM_WELCOME_ENABLED: data.customWelcomeHTMLEnabled,
                    REACT_APP_SIGNATURE_PROFILE_LIMIT: data.signatureProfileLimit,
                    REACT_APP_SIGNING_WITH_MICRO_SIGNER_ENABLED: data.signingWithMicrosignerEnabled,
                    REACT_APP_SIGNING_WITH_QR_ENABLED: data.signingWithQrEnabled,
                    REACT_APP_SIGNING_WITH_PUSH_ENABLED: data.signingWithPushEnabled,
                    REACT_APP_IS_ACCOUNT_DELETION_ENABLED: data.accountDeletionForUsersEnabled,
                    REACT_APP_IS_SIGNATURE_PROFILE_MODIFICATION_ENABLED: data.signatureProfileModificationForUsersEnabled,
                    REACT_APP_IS_TIMESTAMP_CREDENTIAL_MODIFICATION_ENABLED: data.timestampCredentialModificationForUsersEnabled,
                    REACT_APP_AD_WITH_MOBILE_ENABLED: data.adWithMobileEnabled,
                    REACT_APP_AUTHORIZATION_HEADER_KEY: data.authorizationHeaderKey,
                    REACT_APP_PDF_GENERATION_ENABLED: data.pdfGenerationEnabled,
                    REACT_APP_DATABASE_CLEANUP_ENABLED: data.databaseCleanUpEnabled,
                    REACT_APP_MAX_FILENAME_LENGTH_WITHOUT_EXTENSION : data.maxFileNameLengthWithExtension - maxExtensionLength,
                    REACT_APP_BACKEND_VERSION : data.version,
                    REACT_APP_IS_MODIFIED_INDICATOR_ENABLED: data.isContainerModifiedAfterSignatureIndicatorEnabled,
                    REACT_APP_VISIBLE_SIGNATURE_CONSTRAINTS: data.visibleSignatureConstraints
                };
                this._isMounted && this.setState({ clientConfigGot: true });
            })
            .catch((error) => {
                this._isMounted && this.setState({ error: error });
            });
    }

    render() {
        const { clientConfigGot, error } = this.state;
        if (clientConfigGot) {
            return (
                <Provider store={store}>
                    <span>
                        <Router history={getHistory()}>
                            {
                                window.config.REACT_ONLY_ADMIN !== true &&
                                <Switch>
                                    <Route path={'/new/:email/:id'} component={withTracker(NewUserEmailRegister)} />
                                    <Route path={'/registered/:email/:id'} component={withTracker(Registered)} />
                                    <Route path={'/registeredAD/:email/:id'} component={RegisteredAD} />
                                    {!window.config.REACT_APP_SAML_URL && (
                                        <Route path={'/login'} component={withTracker(Login)} />
                                    )}
                                    {window.config.REACT_ADMIN_ENABLE !== false &&
                                        <Route path={'/adminLogin'} component={withTracker(AdminLogin)} />
                                    }
                                    {window.config.REACT_APP_SAML_URL && <Route path={'/login'} component={Saml} />}
                                    <Route path={'/logout'} component={withTracker(Logout)} />
                                    <Route path={'/loginAD'} component={LoginAD} />
                                    <Route path={'/confirm/:token?'} component={withTracker(Confirm)} />
                                    <Route path={'/changelog'} component={withTracker(Changelog)} />
                                    <Route path={'/newpassword/:id'} component={ChangePassword} />
                                    <Route path={'/setpassword/:id'} component={ChangePassword} />
                                    <Route path={'/download/:type?/:id'} component={DownloadFile} />
                                    <Route path={'/resetsecondfactor/:token'} component={withTracker(ResetSecondFactor)} />
                                    <PrivateRoute path={'/settings'} component={withTracker(Settings)} />
                                    <PrivateRoute path={'/info'} component={withTracker(InfoPage)} />
                                    <PrivateRoute path={'/emails'} component={withTracker(FrequentlyUsedEmailAddresses)} />
                                    <PrivateRoute path={'/bulkSigning'} component={withTracker(BulkSigning)} />
                                    <PrivateRoute path={'/waitingToSignByOthers'} component={withTracker(WorkflowPage)} />

                                    <PrivateRoute path={'/adminPage'} component={withTracker(AdminPage)} />
                                    <PrivateRoute path={'/user/:email?'} component={withTracker(EditUser)} />

                                    <PrivateRoute path={'/:id?'} component={withTracker(HomePage)} exact={true} />
                                </Switch>}
                            {
                                window.config.REACT_ONLY_ADMIN === true &&
                                window.config.REACT_ADMIN_ENABLE !== false &&
                                <Switch>
                                    <Route path={'/login'} component={withTracker(AdminLogin)} />
                                    <Route path={'/logout'} component={withTracker(Logout)} />
                                    <PrivateRoute path={'/info'} component={withTracker(InfoPage)} />
                                    <PrivateRoute path={'/adminPage'} component={withTracker(AdminPage)} />
                                    <PrivateRoute path={'/user/:email?'} component={withTracker(EditUser)} />
                                    <PrivateRoute path={'/'} component={withTracker(AdminPage)} />
                                </Switch>
                            }
                        </Router>
                        {ReactDOM.createPortal(<ModalContainer />, document.getElementById('modal-root'))}
                    </span>
                </Provider>
            );
        }
        if (error) {
            return <Alert type="error" message={'Failed to download configuraton properties!'} banner />;
        }
        return (
            <div style={{ position: 'fixed', top: '50%', left: '50%', marginTop: '-32px', marginLeft: '-32px' }}>
                <Spin size="large" />
            </div>
        );
    }
}

loadLocales(function () {
    ReactDOM.render(<App />, document.getElementById('root'));
});

unregister();

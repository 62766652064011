import CustomAlert from '../CustomAlert';
import FormItem from '../form/FormItem';
import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { createUsers } from '../../actions/admin';
import { removeModal } from '../../actions/modal';
import authService from '../../services/authService';
import { createNotification, createNotificationShort, validatePassword } from '../../helper';
import { isEmpty } from 'lodash';

class CreateNewUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: null,
            userType: 'normal',
            language: 'hu',
            shouldRequireTimestampPassword: false,
            shouldRequireTimestampUsername: false
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = (e) => {
        const { form, modalData, createUser, hideModal, translate } = this.props;
        const { language, userType } = this.state;
        e.preventDefault();
        form.validateFieldsAndScroll((clientErr, values) => {
            if (!clientErr) {
                hideModal();

                values.language = language;
                values.role = userType;

                createUser(values)
                    .then(() => {
                        createNotificationShort(this.props.translate, {
                            message: this.props.translate('adminPage.users.successfullyCreated'),
                            type: 'success',
                        });

                        if (modalData && modalData.onUserCreated) {
                            // User data (except passwords).
                            const userData = (({ tsPassword, ...value }) => value)(values);
                            modalData.onUserCreated(userData);
                        }
                    })
                    .catch((e) => {
                        createNotification(this.props.translate, e.response.data.code, {
                            message:
                                translate('adminPage.users.newUserError') +
                                '. ' +
                                translate(`errorCodes.${e.response.data.code}`),
                            type: 'error',
                        });
                    });
            }
        });
    };

    handleUserTypeChange = (value) => {
        this._isMounted && this.setState({ userType: value });
    };

    handleLanguageChange = (value) => {
        this._isMounted && this.setState({ language: value });
    };

    handleTimestampUsernameChange = (e) => {
        const newValue = e.target.value;
        this._isMounted &&
            this.setState(
                {
                    shouldRequireTimestampPassword: newValue && newValue !== '',
                },
                () => {
                    this.props.form.validateFields(['tsPassword'], { force: true });
                }
            );
    };

    handleTimestampPasswordChange = (e) => {
        const newValue = e.target.value;
        this._isMounted &&
            this.setState(
                {
                    shouldRequireTimestampUsername: newValue && newValue !== '',
                },
                () => {
                    this.props.form.validateFields(['tsUser'], { force: true });
                }
            );
    };

    handlePasswordChange = () => {
        const { form } = this.props;

        form.validateFields([ 'password' ], { force: true });
    };

    validatePassword = (rule, value, callback) => {
        const { translate } = this.props;

        if (isEmpty(value)) {
            callback();
        } else {
            validatePassword(translate, value, callback, () => { }, undefined, false);
        }
    };

    render() {
        const { translate } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { serverError } = this.state;
        const Option = Select.Option;

        return (
            <ModalWrapper {...this.props} title={this.props.translate('adminPage.users.newUser')}>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem>{serverError && <CustomAlert error={serverError} />}</FormItem>
                    <FormItem formItemProps={{ label: translate('registration.emailInput') }}>
                        {getFieldDecorator('email', {
                            rules: [{ type: 'email', required: true, message: translate('login.validateEmail') }],
                            normalize: (input) => (input !== undefined ? input.toLowerCase() : ''),
                        })(
                            <Input
                                autoComplete={'new-password'}
                                placeholder={translate('registration.emailInput')}
                                type={'text'}
                            />
                        )}
                    </FormItem>
                    <FormItem formItemProps={{ label: translate('adminPage.users.userType') }}>
                        <Select defaultValue="normal" onChange={this.handleUserTypeChange}>
                            <Option value="admin">{translate('userRole.admin')}</Option>
                            <Option value="normal">{translate('userRole.normal')}</Option>
                        </Select>
                    </FormItem>
                    {authService.getPasswordEnabled() && (
                        <FormItem formItemProps={{ label: translate('adminPage.users.setPassword') }}>
                            { getFieldDecorator('password', {
                                rules: [
                                    { required: false },
                                    { validator: this.validatePassword } ] }) (
                                <Input.Password
                                    type = { 'password' }
                                    placeholder = { translate('login.passwordInput') }
                                    autoComplete = { 'password' }
                                    onChange = { this.handlePasswordChange }
                                />
                            )}
                        </FormItem>
                        )}
                    <FormItem formItemProps={{ label: translate('adminPage.users.tsUser') }}>
                        {getFieldDecorator('tsUser', {
                            rules: [
                                {
                                    required: this.state.shouldRequireTimestampUsername,
                                    message: translate('adminPage.users.required.tsUser'),
                                },
                            ],
                        })(
                            <Input
                                autoComplete={'new-password'}
                                placeholder={translate('modals.tsa.fields.tsaUser.placeholder')}
                                type={'text'}
                                onChange={this.handleTimestampUsernameChange}
                            />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('tsPassword', {
                            rules: [
                                {
                                    required: this.state.shouldRequireTimestampPassword,
                                    message: translate('adminPage.users.required.tsPassword'),
                                },
                            ],
                        })(
                            <Input.Password
                                autoComplete={'new-password'}
                                placeholder={translate('login.passwordInput')}
                                onChange={this.handleTimestampPasswordChange}
                            />
                        )}
                    </FormItem>
                    <FormItem formItemProps={{ label: translate('adminPage.users.language') }}>
                        <Select defaultValue="hu" onChange={this.handleLanguageChange}>
                            <Option value="hu">{translate('languages.hu')}</Option>
                            <Option value="en">{translate('languages.en')}</Option>
                            <Option value="pl">{translate('languages.pl')}</Option>
                        </Select>
                    </FormItem>
                    <Button type="primary" block htmlType="submit">
                        {translate('adminPage.users.create')}
                    </Button>
                </Form>
            </ModalWrapper>
        );
    }
}

CreateNewUserModal.propTypes = {
    createUser: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func,
    hideModal: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
        currentLanguage: getActiveLanguage(state.locale).code,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        hideModal: () => {
            dispatch(removeModal());
        },
        createUser: (inputValues) => {
            return dispatch(createUsers(inputValues));
        }
    };
}

const WrappedCreateNewUser = Form.create()(CreateNewUserModal);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedCreateNewUser);

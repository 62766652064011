import DocumentControls from '../DocumentControls';
import DocumentList from './DocumentList';
import React, {useState} from 'react';
import {TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS, TAB_KEY_DEFAULT_CONTAINERS} from '../../constants/strings';
import {Alert, Empty} from 'antd';
import {getTranslate} from 'react-localize-redux';
import {makeGetActiveTabContainerById} from '../../selectors';
import {createNotification, shouldSignAuto} from '../../helper';
import {updateContainerData} from '../../actions/container';
import {useDeepCompareEffect} from 'react-use';
import {useDispatch, useSelector} from 'react-redux';
import {toggleModal} from '../../actions/modal';
import SkeletonDocumentPage from '../widget/SkeletonDocumentPage';
import * as types from "../../actions/actionTypes";

export default function DocumentPage(props) {
    const dispatch = useDispatch();

    const [container, setContainer] = useState();
    const [tableLoading, setTableLoading] = useState(false);

    const translate = useSelector((state) => getTranslate(state.locale));
    const documents = useSelector((state) => state.documentList.documents);
    const getActiveCont = makeGetActiveTabContainerById();
    const selectedContainer = useSelector((state) =>
        state.containerList.selectedContainers.length > 0
            ? state.containerList.selectedContainers[0]
            : getActiveCont(state, props)
    );
    const isDeprecatedCompanyRegistrationSchema = (container) => {
        if (container && container.esNamespace) {
            return container.esNamespace === 'http://www.e-cegjegyzek.hu/2014/e-cegeljaras#';
        }
        return false;
    };
    const isContainerSigned = useSelector((state) => !!state.containerList.signatures.length);
    const containerSignatures = useSelector((state) => state.containerList.signatures)
    const containers = useSelector((state) => state.containerList.containers);
    const containersInSignatureProcess = useSelector((state) => state.containerList.containersInSignatureProcess);
    const currentTab = useSelector((state) => state.tabs.current);
    const updatedContainer = useSelector((state) => state.containerList.updatedContainer);

    useDeepCompareEffect(() => {
        if (selectedContainer) {
            dispatch({ type: types.UPDATE_CONTAINER_START });
            dispatch(updateContainerData(selectedContainer.data.id, currentTab === TAB_KEY_DEFAULT_CONTAINERS, selectedContainer.data))
                .then((response) => {
                    if (shouldSignAuto(response) && window.config.REACT_APP_PDF_FORM_START_SIGN_AUTO) {
                        dispatch(
                            toggleModal('PDF_WITH_FORM_NOTIFICATION', {
                                container: response,
                                showPdfPreview: false
                            })
                        );
                    }
                })
                .catch((error) => {
                    if (!error.response) {
                        console.error(error);
                    } else {
                        createNotification(translate, error.response.data.code, {
                            message: translate('notifications.getDocumentData.failure'),
                            type: 'error'
                        });
                    }
                });
        }
    }, [selectedContainer]);

    useDeepCompareEffect(() => {
        updatedContainer && setContainer(updatedContainer.data);
    }, [updatedContainer]);

    const isPDF = selectedContainer && selectedContainer.data.type === 'pdf';
    const containersInUse = (containers.length > 0 && currentTab === TAB_KEY_DEFAULT_CONTAINERS) ||
        (containersInSignatureProcess.length > 0 && currentTab === TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS);
    const documentContainersSignatureIsModified = containerSignatures.map(signature => signature.isModifiedAfterSigning).pop();

    if (container && (!selectedContainer || container.id === selectedContainer.data.id)) {
        return (
            <React.Fragment>
                {containersInUse && selectedContainer ? (
                    <React.Fragment>
                        <DocumentControls
                            container={container}
                            documents={documents}
                            hasDocuments={documents.length > 0}
                            isContainerSigned={isContainerSigned}
                            isDeprecatedCompanyRegistrationSchema={isDeprecatedCompanyRegistrationSchema(container)}
                            isUploadDisabled={!container.isUploadEnabled}
                            setTableLoadingTrue={() => setTableLoading(true)}
                            setTableLoadingFalse={() => setTableLoading(false)}
                        />
                        {container.type === 'pdf' && shouldSignAuto(container) && (
                            <Alert
                                type='info'
                                message={translate('modals.pdfWithForm.subTitle')}
                                banner
                            />
                        )}
                        {window.config.REACT_APP_IS_MODIFIED_INDICATOR_ENABLED &&
                            documentContainersSignatureIsModified &&
                            (<Alert style={{ marginTop: '8px' }} message={translate('signature.isModified')} type="warning" showIcon />)}
                        {container.corrupted ?
                            (<Alert style={{ marginTop: '8px' }} message={translate('container.corrupted')} type="warning" showIcon />) :
                            ( <DocumentList
                            container={container}
                            isContainerSigned={isContainerSigned}
                            isDeprecatedCompanyRegistrationSchema={isDeprecatedCompanyRegistrationSchema(container)}
                            isUploadDisabled={!container.isUploadEnabled}
                            tableLoading={tableLoading}
                            setTableLoadingTrue={() => setTableLoading(true)}
                            setTableLoadingFalse={() => setTableLoading(false)}
                            /> )}
                    </React.Fragment>
                ) : (
                    <Empty
                        style={{ marginTop: 20 }}
                        description={<span>{translate('containerNotSelected')}</span>}
                    />
                )}
            </React.Fragment>
        );
    } else {
        return (<SkeletonDocumentPage isPDF={isPDF} />);
    }
};
